.widget__thankyou__ctn {
    width: 100%;
}

.thankyou__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3.5em 1.75em 2em 1.75em;
    background-color: var(--white);
}

.thankyou__header__checkmark {
    color: var(--white);
    background: var(--valid);
    padding: 0.6em;
    text-align: center;
    width: 1em;
    height: 1em;
    line-height: 1em;
    font-size: 1.9em;
    font-weight: 900;
    border-radius: 100%;
}

.thankyou__header__heading {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 1.4em;
}

.thankyou__header__heading>span {
    text-transform: capitalize;
}

.thankyou__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.75em;
    background-color: var(--pl-grey-light);
}

.thankyou__footer__heading {
    font-size: 1em;
}

.thankyou__footer__list {
    width: 400px;
    max-width: 90%;
    margin: 1em auto;
    color: var(--pl-grey);
    list-style: none;
}

.thankyou__footer__list>li {
    position: relative;
    font-size: 0.9em;
    margin-bottom: 0.6em;
    padding-left: 1.2em;
}

.thankyou__footer__list>li::before {
    content: "\2714";
    position: absolute;
    color: var(--pl-pink);
    font-weight: 900;
    font-size: 1.2em;
    left: -1em;
}

.thankyou__footer_button {
    margin-top: 2em;
    padding: 0.65em 3em;
    color: var(--pl-pink);
    font-size: 1em;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    outline: none;
    border: 2px solid var(--pl-pink);
    border-radius: 100vmax;
    transition: all 0.3s ease;
    cursor: pointer;
}

.thankyou__footer_button:hover,
.thankyou__footer_button:focus {
    color: var(--pl-pink-light);
    border: 2px solid var(--pl-pink-light);
}