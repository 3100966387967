.footer {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: calc(100% - 4em);
    padding: 0 2em 2em 2em;
    font-size: 0.7em;
    color: var(--white);
}

.footer__separator {
    display: block;
    width: 0.3em;
    height: 0.3em;
    padding: 0;
    margin: auto 1em;
    background-color: var(--white);
    border-radius: 100%;
}

.footer__item {
    margin: 0;
    padding: 0;
    line-height: 1em;
}

.footer__item__link {
    color: var(--white);
    text-decoration: underline transparent;
    transition: all 0.3s ease;
}

.footer__item__link:hover{
    text-decoration: underline var(--white);
    color: inherit;
}