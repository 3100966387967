/* CSS RESET */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* END CSS RESET */


html, body {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
}

body {
    position: absolute;
    overflow-x: hidden;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    background-color: #ec008c;
    background-color: var(--pl-pink);
    font-family: var(--body-font);
    font-size: 17px;
    -webkit-font-smoothing: antialiased;
}

@media only screen and (max-width: 900px) {
    body{
        font-size: 15px;
    }
}

h1, h2, h3, h4, h5, h6, p, a, li, ul, ol, strong, span, button, fieldset, form, input, label, option, select, textarea{
	margin: 0;
	padding: 0;
	line-height: 1.5em;
}

button, fieldset, form, input, label, option, select, textarea{
	font-family: inherit;
}

p, ul, ol{
	margin-bottom: 0.8em;
}

ul,ol{
	margin-left: 1em;
}

li{
	padding-left: 0.75em;
}

a{
	text-decoration: 0.1em underline solid;
	color: var(--pl-pink);
    transition: all 0.3s ease;
}
a:hover,
a:focus{
    color: var(--pl-pink-light);
    border: none;
    outline: none;
}

h1, h2, h3, h4, h5, h6{
	font-family: var(--heading-font);
	font-weight: 700;
	margin-bottom: 0.5em;
}

main{
    padding-bottom: 10em;
    min-height: calc(100vh - 16em);
}

::-webkit-scrollbar{
    width: 8px;
}
::-webkit-scrollbar-track{
    background: var(--pl-grey-light);
    padding: 1px;
}
::-webkit-scrollbar-thumb{
    background: var(--pl-green);
    border-radius: 100vmax;
}
::-webkit-scrollbar-thumb:hover{
    background: var(--pl-green-light);
}