.form__field__ctn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 0 0 1em 0;
    padding: 0;
    text-align: left;
}

.form__field__ctn>span {
    position: relative;
    width: 100%;
}

.form__field__input {
    width: calc(100% - 3em);
    padding: 1.8em 1.5em 0.5em 0.75em;
    line-height: 1em;
    background-color: var(--pl-grey-light);
    outline: none;
    border: 2px solid transparent;
    border-radius: 0.25em;
    transition: all 0.5s ease;
}

.form__field__info-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-family: 'Times New Roman', Times, serif;
    top: 50%;
    right: 1.5rem;
    width: 1em;
    height: 1em;
    font-size: 0.65em;
    text-align: center;
    margin: 0;
    padding: 0.2em;
    border: 0.2em solid var(--pl-grey);
    border-radius: 100%;
    transform: translateY(-50%);
    opacity: 0.6;
    z-index: 2;
    cursor: pointer;
    user-select: none;
}

.form__field__info-icon>span {
    font-weight: 900;

    color: var(--pl-grey);
    cursor: pointer;
}

input[type="date"] {
    display: block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: calc(100% - 1em);
    min-width: calc(100% - 3em);
    padding: 1.8em 1.5em 0.5em 0.75em;
}

input[type=date]::-webkit-datetime-edit {
    color: var(--pl-grey);
    text-transform: uppercase;
    transition: all 0.3s ease;
}

input[type=date][value='']::-webkit-datetime-edit {
    color: transparent;
}

input[type=date]:focus::-webkit-datetime-edit {
    color: var(--pl-grey) !important;
}

input[type=date]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 50%;
    right: 1.5em;
    transform: translateY(-50%);
    border-radius: 100%;
    transition: all 0.3s ease;
}

.form__field__input__label {
    position: absolute;
    top: 1.3em;
    left: 1em;
    margin: 0;
    padding: 0;
    font-weight: 700;
    font-size: 0.7em;
    color: var(--pl-grey);
    transition: all 0.3s ease;
    transform: translateY(-50%);
}

.form__field__input:required+label::after,
.form__field__checkbox:required+label>span::after {
    content: "*";
    font-weight: 900;
    color: var(--pl-pink);
    padding-left: 0.25em;
}

.form__field__input[value=""]+label {
    left: 1.5em;
    top: 50%;
    font-weight: 400;
    font-size: 0.85em;
}

.form__field__input:focus+label {
    top: 1.3em;
    left: 1em;
    font-weight: 700;
    font-size: 0.7em;
}

.form__field__input:invalid,
.form__field__input:valid,
.form__field__input:focus:invalid {
    border-radius: 0.25em 0.25em 0 0;
}

.form__field__input:invalid {
    border-bottom: 2px solid var(--error);
}

.form__field__input:valid {
    border-bottom: 2px solid var(--pl-green);
}

.form__field__input[value=""] {
    border-radius: 0.25em;
    border-bottom: 2px solid transparent;
}

.form__field__input:focus:invalid {
    border-bottom: 2px solid var(--error);
}

.form__field__checkbox {
    margin-left: -1em;
    opacity: 0;
    transition: all 0.3s ease;
}

.form__field__checkbox+label {
    position: relative;
    cursor: pointer;
    padding: 0;
}

.form__field__checkbox+label::before {
    content: "";
    margin-right: 1.5em;
    display: inline-block;
    vertical-align: text-top;
    width: 1.5em;
    height: 1.5em;
    background: var(--pl-grey-light);
    border-radius: 0.2em;
    transition: all 0.3s ease;
}

.form__field__checkbox:hover+label::before,
.form__field__checkbox:focus+label::before {
    background: rgba(236, 72, 151, 0.301);
}

.form__field__checkbox:checked+label::before {
    background: var(--pl-pink);
}

.form__field__checkbox:disabled+label {
    color: var(--pl-d);
    cursor: auto;
}

.form__field__checkbox:disabled+label::before {
    box-shadow: none;
    background: var(--pl-grey-light);
}

.form__field__checkbox:checked+label::after {
    content: "";
    position: absolute;
    left: 0.4em;
    top: 0.65em;
    background: white;
    width: 0.2em;
    height: 0.2em;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
}

.form__field__checkbox__label {
    font-size: 0.85em;
    color: var(--pl-grey);
    transition: all 0.3s ease
}

.form__field__checkbox__label>span {
    display: inline-block;
    max-width: calc(100% - 4.5em);
    margin-left: 3em;
    margin-top: -10em;
}

.form__field__error-text {
    margin: 1em auto 0 auto;
    color: var(--error);
    font-size: 0.6em;
    text-align: center;
}

.widget__form:not(.attempted-submit) .form__field__ctn>span:focus-within~.form__field__error-text {
    display: none !important;
}

.widget__form.attempted-submit .form__field__error-text {
    display: inherit !important;
}