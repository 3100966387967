.widget__form__ctn {
    padding: 3.5em 1.75em;
}

.widget__form {
    width: 400px;
    max-width: 90%;
    margin: 2em auto;
    text-align: center;
}

.form__section {
    margin-bottom: 1em;
}

.form__section__heading {
    margin: 1em 0;
    font-size: 1em;
    font-family: var(--heading-font);
    font-weight: 700;
    text-align: left;
}

.form__join-link{
    margin: 0 0 2em 0;
    padding: 0;
    color: var(--pl-grey);
    font-size: 0.7em;
    text-align: left;
}

.form__submit__ctn {
    position: relative;
}

#form__submit__button {
    -webkit-appearance: none;
    min-width: 15em;
    margin-top: 2em;
    padding: 0.65em 5em;
    color: var(--white);
    background-color: var(--pl-pink);
    font-size: 1em;
    text-transform: uppercase;
    outline: none;
    border: none;
    border-radius: 100vmax;
    transition: all 0.3s ease;
    cursor: pointer;
}

#form__submit__button:disabled,
#form__submit__button:disabled:focus,
#form__submit__button:disabled:hover {
    background-color: var(--pl-grey);
    cursor: not-allowed;
}

#form__submit__button:hover,
#form__submit__button:focus {
    background-color: var(--pl-pink-light);
}

.form__submit__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.4em;
    height: 1.4em;
}

@keyframes rotateCircle {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.form__submit__loading>span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: rotateCircle 1.3s cubic-bezier(.32, .47, .72, .53) infinite;
    transform-origin: center;
    cursor: not-allowed;
}

.form__submit__loading>span:nth-last-of-type(1) {
    animation-delay: 0.2;
}

.form__submit__loading>span::before {
    content: "";
    position: absolute;
    width: 0.4em;
    height: 0.4em;
    top: 0;
    left: 55%;
    background: var(--white);
    border-radius: 100%;
    cursor: not-allowed;
}

.form__submit__loading>span:nth-of-type(1)::before {
    top: 0em;
    left: 15%;
    width: 0.3em;
    height: 0.3em;
}

.form__submit__loading>span:nth-of-type(2)::before {
    top: 0.5em;
    left: -5%;
    width: 0.25em;
    height: 0.25em;
}

.form__submit__error {
    color: var(--error);
    margin: 2em 0 0 0;
    font-size: 0.7em;
}
.form__submit__error a{
    color: inherit;
}

.form__consent__button {
    text-decoration: 0.1em underline solid;
    font-size: 1em;
    color: var(--pl-pink);
    transition: all 0.3s ease;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.form__consent__button:hover,
.form__consent__button:focus {
    color: var(--pl-pink-light);
}