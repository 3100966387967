.widget {
    min-width: 300px;
    width: 700px;
    max-width: 92%;
    margin: -1em auto;
    padding: 0;
    background-color: var(--white);
    border-radius: 1em;
    box-shadow: 0 0 35px 0 rgb(0 0 0 / 0.1);
    overflow: hidden;
    z-index: 10;
}

.widget__heading {
    margin-bottom: 0.5em;
    color: var(--pl-pink);
    font-size: 1.6em;
    line-height: 1.2em;
    text-align: center;
}

.widget__subheading {
    margin: 1.5em 0;
    color: var(--pl-grey);
    text-align: center;
    font-size: 1.2em;
    font-weight: 500;
}

.widget__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2em;
    margin: 7em auto 0 auto;
}

.widget__logo--priceline{
    height: 100%;
    padding-right: 1em;
    border-right: 1px solid var(--pl-grey);
}

.widget__logo--myDNA{
    height: 100%;
    margin-left: 1em;
}

@media only screen and (max-width:900px) {
    .widget {
        font-size: 0.9em;
    }
}