.header {
    width: 100%;
    z-index: -1;
    background-color: var(--white);
}

.header__ctn {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 4em);
    min-height: 11em;
    padding: 2em;
    font-size: 15px;
}

.header__wavy-line--outer {
    position: absolute;
    right: -1%;
    width: 102%;
    margin-top: -1px;
    margin-bottom: -6px;
    min-width: 800px;
    background-color: var(--white);
    pointer-events: none;
    user-select: none;
    z-index: -1;
}

.header__logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 2;
}

.header__logo--priceline {
    height: 3em;
    padding-right: 2em;
    border-right: 1px solid var(--pl-grey);
}

.header__logo--myDNA {
    height: 2.5em;
    margin-left: 2em;
}

.header__logo--know-your-skin{
    margin: 2em auto;
    width: 40%;
    max-width: 250px;
    min-width: 130px;
    height: auto;
}

@media screen and (max-width: 900px) {
    .header__ctn {
        align-items: center;
        font-size: 13px;
    }
}