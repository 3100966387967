:root{
    --white: #ffffff;
    --black: #000000;
    --pl-pink: #ec008c;
    --pl-pink-light: #e95bb0;
    --pl-green: #8dc63f;
    --pl-green-light: #aac487;
    --pl-grey: #5F5F5F;
    --pl-grey-light: #ededed;

    --valid: #6CB31C;
    --error: #ff0000;

    --heading-font: Helvetica, Arial, sans-serif;
    --body-font: Helvetica, Arial, sans-serif;
}