.popup {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 0.5);
    z-index: 999;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.popup.visible {
    opacity: 1;
}

.popup__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: calc(100% - 6em);
    max-width: 500px;
    max-height: calc(100% - 10em);
    min-height: 10em;
    padding: 2.5em 2em;
    background-color: var(--white);
    border-radius: 1em;
    overflow: hidden;
    box-shadow: 0 0 25px 0 rgb(0 0 0 / 0.2);
}

.popup__inner__close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    border: none;
    outline: none;
    background: transparent;
    color: var(--pl-grey);
    font-size: 1.2em;
    font-weight: 100;
    line-height: 1em;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.popup__inner__children {
    height: 100%;
    width: calc(100% - 1em);
    margin-top: 1em;
    padding: 0 1em 1em 0;
    overflow: hidden scroll;
    text-align: left;
}

.popup__inner__children::-webkit-scrollbar {
    width: 4px;
}

.popup__inner__children::-webkit-scrollbar-track {
    background: var(--white);
}

.popup__inner__children::-webkit-scrollbar-thumb {
    background: var(--pl-green);
}

.popup__inner__children::after {
    content: "";
    position: absolute;
    bottom: 2.5em;
    left: 0;
    width: 100%;
    height: 30px;
    background: linear-gradient(0deg, var(--white), transparent);
}