.informed-consent {
    position: relative;
    font-size: 0.85em;
}

.informed-consent h2 {
    text-align: center;
    margin-bottom: 2em;
}

.informed-consent h3 {
    margin-top: 1.5em;
}